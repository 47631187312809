
import React from 'react'
import DataTable from 'react-data-table-component';

const columns = [
    {
        name: 'User Name',
        selector: row => row.full_name,
    },
    {
        name: 'Coupon Used',
        selector: row => row.promotion_code,
       
    },
    {
        name: 'Spin Create Time',
        selector: row => row.createdAt,
        sortable:true,
    },
    {
        name: 'email',
        selector: row => row.email,
    },
    {
        name:'Prize Alloted',
        selector: row => row?.rewards[0]?.reward?.name,
    }
];


function UserTable({participants}) {
  return (
    <DataTable
    columns={columns}
    data={participants||[]}
    theme='dark'
    pagination
/>
  )
}

export default UserTable