import CR300R from "../../assets/images/prize-images/300CR.png";
import NT2W from "../../assets/images/prize-images/2NightW.png";
import CR200B from "../../assets/images/prize-images/200CB.png";
import CR400R from "../../assets/images/prize-images/400CR.png";
import CR300W from "../../assets/images/prize-images/300CW.png";
import CR500B from "../../assets/images/prize-images/500CB.png";
import NT1W from "../../assets/images/prize-images/1NightW.png";
import CR500W from "../../assets/images/prize-images/500CW.png";
import CR300R_fr from "../../assets/images/prize-images_fr/300CR-fr.png";
import NT2W_fr from "../../assets/images/prize-images_fr/2NightW-fr.png";
import CR200B_fr from "../../assets/images/prize-images_fr/200CB-fr.png";
import CR400R_fr from "../../assets/images/prize-images_fr/400CR-fr.png";
import CR300W_fr from "../../assets/images/prize-images_fr/300CW-fr.png";
import CR500B_fr from "../../assets/images/prize-images_fr/500CB-fr.png";
import NT1W_fr from "../../assets/images/prize-images_fr/1NightW-fr.png";
import CR500W_fr from "../../assets/images/prize-images_fr/500CW-fr.png";


export const generateDescription=(prizeDescription,currentLangauage)=>{
try{
  const frenchText={
    "$200 Hotel Credit":"$200 CRÉDIT D’HÔTEL",
    "$300 Hotel Credit":"$300 CRÉDIT D’HÔTEL",
    "$400 Hotel Credit":"$400 CRÉDIT D’HÔTEL",
    "$500 Hotel Credit":"$500 CRÉDIT D’HÔTEL",
    "1 Night Hotel Stay":"1 NUITS D’HÔTEL",
    "2 Night Hotel Stay":"2 NUITS D’HÔTEL",
  }
  if(currentLangauage==='fr')
  {
    return frenchText[prizeDescription]
  }
  return prizeDescription
}
catch{
  return prizeDescription
}

}

export const PrizeDataForSpinWheel = [
  {
    option: `$300 Hotel Credit`,
    style: { backgroundColor: "#CB0B2E", textColor: "#00164F" },
    image: { uri: CR300R, offsetX: 2, offsetY: 100, sizeMultiplier: 1.6 },
  },
  {
    option: "2 Night Hotel Stay",
    style: { backgroundColor: "white", textColor: "White" },
    image: { uri: NT2W, offsetX: 2, offsetY: 90, sizeMultiplier: 1.6 },
  },
  {
    option: "$200 Hotel Credit",
    style: { backgroundColor: "#00164F", textColor: "White" },
    image: { uri: CR200B, offsetX: 2, offsetY: 120, sizeMultiplier: 1.5 },
  },
  {
    option: "$400 Hotel Credit",
    style: { backgroundColor: "#CB0B2E", textColor: "#00164F" },
    image: { uri: CR400R, offsetX: 2, offsetY: 100, sizeMultiplier: 1.5 },
  },
  {
    option: "$300 Hotel Credit",
    style: { backgroundColor: "white", textColor: "White" },
    image: { uri: CR300W, offsetX: 2, offsetY: 100, sizeMultiplier: 1.6 },
  },
  {
    option: "$500 Hotel Credit",
    style: { backgroundColor: "#00164F", textColor: "White" },
    image: { uri: CR500B, offsetX: 2, offsetY: 120, sizeMultiplier: 1.5 },
  },
  {
    option: "$300 Hotel Credit",
    style: { backgroundColor: "#CB0B2E", textColor: "#00164F" },
    image: { uri: CR300R, offsetX: 2, offsetY: 110, sizeMultiplier: 1.5 },
  },
  {
    option: "1 Night Hotel Stay",
    style: { backgroundColor: "white", textColor: "White" },
    image: { uri: NT1W, offsetX: 2, offsetY: 100, sizeMultiplier: 1.5 },
  },
  {
    option: "$200 Hotel Credit",
    style: { backgroundColor: "#00164F", textColor: "White" },
    image: { uri: CR200B, offsetX: 2, offsetY: 120, sizeMultiplier: 1.5 },
  },
  {
    option: "$400 Hotel Credit",
    style: { backgroundColor: "#CB0B2E", textColor: "#00164F" },
    image: { uri: CR400R, offsetX: 2, offsetY: 120, sizeMultiplier: 1.5 },
  },
  {
    option: "$500 Hotel Credit",
    style: { backgroundColor: "white", textColor: "White" },
    image: { uri: CR500W, offsetX: 2, offsetY: 120, sizeMultiplier: 1.6 },
  },
  {
    option: "$200 Hotel Credit",
    style: { backgroundColor: "#00164F", textColor: "White" },
    image: { uri: CR200B, offsetX: 2, offsetY: 120, sizeMultiplier: 1.5 },
  },
];

export const PrizeDataForSpinWheel_fr = [
  {
    option: `$300 Hotel Credit`,
    style: { backgroundColor: "#CB0B2E", textColor: "#00164F" },
    image: { uri: CR300R_fr, offsetX: 2, offsetY: 100, sizeMultiplier: 1.6 },
  },
  {
    option: "2 Night Hotel Stay",
    style: { backgroundColor: "white", textColor: "White" },
    image: { uri: NT2W_fr, offsetX: 2, offsetY: 90, sizeMultiplier: 1.6 },
  },
  {
    option: "$200 Hotel Credit",
    style: { backgroundColor: "#00164F", textColor: "White" },
    image: { uri: CR200B_fr, offsetX: 2, offsetY: 120, sizeMultiplier: 1.5 },
  },
  {
    option: "$400 Hotel Credit",
    style: { backgroundColor: "#CB0B2E", textColor: "#00164F" },
    image: { uri: CR400R_fr, offsetX: 2, offsetY: 100, sizeMultiplier: 1.5 },
  },
  {
    option: "$300 Hotel Credit",
    style: { backgroundColor: "white", textColor: "White" },
    image: { uri: CR300W_fr, offsetX: 2, offsetY: 100, sizeMultiplier: 1.6 },
  },
  {
    option: "$500 Hotel Credit",
    style: { backgroundColor: "#00164F", textColor: "White" },
    image: { uri: CR500B_fr, offsetX: 2, offsetY: 120, sizeMultiplier: 1.5 },
  },
  {
    option: "$300 Hotel Credit",
    style: { backgroundColor: "#CB0B2E", textColor: "#00164F" },
    image: { uri: CR300R_fr, offsetX: 2, offsetY: 110, sizeMultiplier: 1.5 },
  },
  {
    option: "1 Night Hotel Stay",
    style: { backgroundColor: "white", textColor: "White" },
    image: { uri: NT1W_fr, offsetX: 2, offsetY: 100, sizeMultiplier: 1.5 },
  },
  {
    option: "$200 Hotel Credit",
    style: { backgroundColor: "#00164F", textColor: "White" },
    image: { uri: CR200B_fr, offsetX: 2, offsetY: 120, sizeMultiplier: 1.5 },
  },
  {
    option: "$400 Hotel Credit",
    style: { backgroundColor: "#CB0B2E", textColor: "#00164F" },
    image: { uri: CR400R_fr, offsetX: 2, offsetY: 120, sizeMultiplier: 1.5 },
  },
  {
    option: "$500 Hotel Credit",
    style: { backgroundColor: "white", textColor: "White" },
    image: { uri: CR500W_fr, offsetX: 2, offsetY: 120, sizeMultiplier: 1.6 },
  },
  {
    option: "$200 Hotel Credit",
    style: { backgroundColor: "#00164F", textColor: "White" },
    image: { uri: CR200B_fr, offsetX: 2, offsetY: 120, sizeMultiplier: 1.5 },
  },
];

export const arrangePrizeDescription = (prizeName, textMeta) => {
  const splitWords = prizeName.split(" ");
  if(textMeta==="find_dollar_sign")
  {
    if(splitWords[0].charAt(0)==="$")
    {
      return "$"
    }
      return null
  }
  if (textMeta === "prize_numeric_entity") {
    if(splitWords[0].charAt(0)==="$")
    {
      return splitWords[0].slice(1);
    }
    return splitWords[0]
  }
  if (textMeta === "prize_title1_entity") {
    return splitWords[1];
  }
  if (textMeta === "prize_title2_entity") {
    if (splitWords.length === 4) {
      return splitWords[2]+" "+splitWords[3];
    }
    return splitWords[2];
  }
};
