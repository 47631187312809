import React, { useEffect, useState } from "react";
import UserTable from "./userTable/UserTable";
import logo from "../../../../assets/images/TommyIcon.png";
import { useNavigate } from "react-router-dom";
import { customInterceptors } from "../../../../services/config";
import Papa from "papaparse";
import { AdminEndPoints } from "../../../../services/apiEndPoints";
import { toast } from "react-toastify";
import authService from "../../../../services/authServices";

function UserDetails() {
  const navigate = useNavigate();
  const API = customInterceptors();
  const constants = {
    download: {
      INITIAL: "Download CSV",
      DOWNLOADING: "Please Wait...",
    },
    error: {
      CSV_FAIL: "Failed to Download CSV",
      PARTICIPANTS_FETCH_FAILURE: "Failed to Fetch Participants",
    },
  };
  const [downloadButtonText, setDownloadButtonText] = useState(
    constants.download.INITIAL
  );
  const [participants, setParticipants] = useState([]);
  const fetchParticipants = async () => {
    try {
      const response = await API.get(AdminEndPoints.VIEW_PARTICIPANTS);
      if ([200, 201, 202].includes(response.status)) {
        response?.data?.length
          ? setParticipants(response.data)
          : setParticipants([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        authService.clearUser();
        navigate("/admin/login");
        return;
      }
      toast.error(constants.error.PARTICIPANTS_FETCH_FAILURE);
    }
  };
  const download = () => {
    // Convert JSON data to a CSV string
    setDownloadButtonText(constants.download.DOWNLOADING);
    try {
      const filteredCsvData = participants.map(({ rewards, ...rest }) => ({
        ...rest,
        reward: rewards[0]?.reward?.name || "",
      }));
      const csvData = Papa.unparse(filteredCsvData);
      // Creating a Blob containing the CSV data
      const blob = new Blob([csvData], { type: "text/csv" });
      // Creating a URL for the Blob
      const url = URL.createObjectURL(blob);
      // Creating a link element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "Participants.csv";
      // Simulating a click event to trigger the download
      a.click();
      // Clean up by revoking the URL
      URL.revokeObjectURL(url);
    } catch (err) {
      toast.error(constants.error.CSV_FAIL);
    }
    setInterval(() => {
      setDownloadButtonText(constants.download.INITIAL);
    }, 1000);
  };
  useEffect(() => {
    fetchParticipants();
  }, []);

  return (
    <>
      <p className="p-2 flex justify-center  ">
        <img className="h-8" alt={"THlogo-I"} src={logo} />{" "}
      </p>
      <div className="grid grid-cols-1 container mx-auto justify-center  place-content-center gap-5 py-3  ">
        <p className="text-4xl font-[futura] mt-3 lg:text-5xl text-white">
          {"Participants Table"}
        </p>
        <UserTable participants={participants} />
        <div className="flex justify-center">
          <button
            className="bg-white hover:bg-[#D61233] hover:text-white p-2 transition-all duration-200"
            disabled={!participants?.length}
            onClick={download}
          >
            {downloadButtonText}
          </button>
        </div>
      </div>
    </>
  );
}

export default UserDetails;
