import React from "react";
import mailicon from "../../assets/images/mailIcon.png";
import logo from "../../assets/images/TommyIcon.png"
import { useTranslation } from "react-i18next";
import confettiMob from "../../assets/images/confetti.gif";
import { arrangePrizeDescription, generateDescription } from "../helper/PrizeReference";
import { link } from "../helper/constantsLink/links";

function CreateWin({ prizeText,currentLanguage}) {
  let prizeDescription=generateDescription(prizeText,currentLanguage)
  const { t } = useTranslation();
  return prizeDescription ? (
    <>
      <div>
        <div className="absolute h-[100%] overflow-hidden opacity-40 -z-10">
          <img src={confettiMob} alt="confetti-I"></img>
        </div>
        <p className="text-2xl p-2 text-white tracking-widest lg:tracking-[0.3em] flex justify-center mt-2 ">
         <img className="h-7" alt={"THlogo-I"} src={logo} />{" "}
        </p>
        <p className="text-5xl font-[futura-bold] mt-8 lg:text-6xl text-white">
          {t("shop_spin_line1").toUpperCase()}
        </p>
        <p className="text-[3.35rem] leading-[1] tracking-widest mt-1 lg:text-[4.10rem] font-[futura] text-[#D61233] ">
          <span className="text-[#02154E]">*</span>{t("shop_spin_line2").toUpperCase()}<sup className="text-3xl lg:text-4xl">*</sup>
        </p>
        <p className="text-5xl font-[futura-bold] mt-1 lg:text-6xl text-white">
          {t("shop_spin_line3").toUpperCase()}
        </p>
        <p className="text-5xl font-[futura-bold] mt-1 lg:text-6xl text-white">
          {t("shop_spin_line4").toUpperCase()}
        </p>
      </div>
      <div className="px-5  ">
        <div className="flex justify-center">
        <hr className="border-t-2 w-[90%] lg:w-[60%] "/>
        </div>
        <div className="grid text-center  py-2 place-content-center">
          <p className="text-3xl lg:text-4xl font-[futura-bold] tracking-wide    text-white">
            {t("congratulations").toUpperCase()}
          </p>
          <p className=" text-[#D61233] font-[futura-bold] text-2xl mt-4">
            {t("you_just_won_a")}
          </p>
          <div className="flex grid-cols-2 gap-x-2 place-content-center">
            <p className="text-7xl flex font-[futura-bold] lg:text-8xl  text-white">
              <span className="text-5xl lg:text-7xl ">{arrangePrizeDescription(prizeDescription,"find_dollar_sign")}</span>{arrangePrizeDescription(prizeDescription,"prize_numeric_entity")}{" "}
            </p>
            <div className="grid gird-cols-1 font-[futura-bold] leading-7 text-3xl lg:text-5xl text-white  place-content-start">
              <span>{arrangePrizeDescription(prizeDescription,"prize_title1_entity").toUpperCase()}</span>
              <span className="">
                {arrangePrizeDescription(prizeDescription,"prize_title2_entity").toUpperCase()}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
        <hr className="border-t-2 w-[90%] lg:w-[60%] "/>
        </div>
      </div>
      <div className="flex gap-4 justify-center">
        <div className="grid place-content-center justify-center">
          <div className="rounded-[50%] h-8 w-8 bg-[#D61233] grid place-content-center">
            <img src={mailicon} width={18} alt="mail-I" />
          </div>
        </div>
        <div className="grid font-[futura] font-medium leading-5 place-content-center text-left text-lg lg:text-2xl  text-white">
          <p>{t("please_check_your").toUpperCase()}</p>
          <p>{t("inbox_for_your_prize").toUpperCase()}<span className="text-[#D61233]">{" "+t('prize').toUpperCase()}</span></p>
        </div>
      </div>
      <p className="text-xs text-white my-5">
      {"*"+ t('see')+" "}<a href={currentLanguage==='fr'?link.terms_fr:link.terms_en}><span className="underline">{t("term_and_conditions")}</span></a>{" "+t("for_details")}
      </p>
    </>
  ) : (
    ""
  );
}

export default CreateWin;
